#ListOfPros {
    margin-top: 5srem;
}

.line {
    height: 4px; /* Adjust thickness */
    width: 80%; /* Adjust length */
    background: linear-gradient(to right, #ff007f, #7f00ff); /* Pink to purple gradient */
    border-radius: 2px; /* Makes the edges rounded */
    margin: auto;
  }
  

  #ListOfProgramSection {
    list-style-type: none;
    display: flex;
    justify-content: space-around;
    font-family: bpg;
    font-size: 26px;
  }

  #ListOfProgramSection li:hover {
        opacity: 0.2;
        cursor: pointer;
        text-decoration: underline #7f00ff;
        text-underline-offset: 1rem;
        
  }

  #listOfMainProst li{
    list-style-type:none;
    font-size: 20px;
    margin-bottom: 0.5rem;
    font-family: NotoSansGeorgian;
}

  #listOfMainProst li::before {
    content: "-";
    margin: 1rem;
  }
#SyrveSection {
    background-image: url("../../assets/img/slide background/slide\ background\ gradient.png");
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
    border-radius: 20px;

}

#SyrveSectionInner {
    width: 100%;
    background-image: url("../../assets/img/slide background/slide\ pentagon\ gradient.png");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 60%;
    height: 100%;
}

#Syrve {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;   
}


#Syrve h2 {
    text-align: center;
    font-size: 300px;
    color: white;
}

#Syrve p {
    font-family: NotoSansGeorgian;
    font-size: 26px;
    max-width: 720px;
    margin-left: 50rem;
    line-height: 2.3rem;
    font-family: 'bpg';


}

#Syrve p span {
    font-size:30px;
    display: block;
    font-weight: 900;
}
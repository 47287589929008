@font-face {
  font-family: LGV;
  src: url(./assets/fonts/LGV_Big_Noodle.ttf);
}

@font-face {
  font-family: NotoSansGeorgian;
  src: url(./assets/fonts/NotoSansGeorgian-VariableFont_wdth\,wght.ttf);
}

@font-face {
  font-family: bpg;
  src: url(./assets/fonts/bpg_banner_quadrosquare_caps.ttf);
}
html {
  scroll-behavior: smooth;
}

body {
  background-color: #292929;
  color: white;
  overflow-x:hidden;
  cursor: url("data:image/svg+xml,%3Csvg%20version%3D%221.1%22%20id%3D%22pointer_cursor%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20x%3D%220px%22%20y%3D%220px%22%20width%3D%2221px%22%20height%3D%2233px%22%20viewBox%3D%220%200%2021%2033%22%20enable-background%3D%22new%200%200%2021%2033%22%20xml%3Aspace%3D%22preserve%22%3E%3Cpolygon%20id%3D%22outer%22%20opacity%3D%220.5%22%20fill%3D%22%23FFFFFF%22%20points%3D%2220.2%2C20.2%2012.25%2C20.2%2016%2C29%209.667%2C31.75%205.75%2C22.5%200%2C28%200%2C0%20%22%2F%3E%3Cpolygon%20id%3D%22inner%22%20opacity%3D%220.5%22%20fill%3D%22%23231F20%22%20points%3D%226.373%2C19.482%201.75%2C23.904%201.75%2C4.225%2015.975%2C18.45%209.602%2C18.45%2013.708%2C28.087%2010.59%2C29.441%20%22%2F%3E%3C%2Fsvg%3E"), auto;

}

.flexbox {
  display: flex;   
}

.SectionNumber {
  padding:2rem 3rem;
  color: gray;
  font-family: bpg;
}

.SectionNumber span {
  opacity: 0.8;
}
.sliding-text-container h2 {
    text-align: center;
    font-family: NotoSansGeorgian;
    font-size: 36px;
    color: #E7E8EA;
}
.sliding-text-container {
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
    position: relative;
    margin-top: 5rem;
  }
  
  .sliding-text {
    display: flex;
    animation: slideText 40s linear infinite;
    justify-content: space-around;
    align-items: center;
    gap:10rem
  }
  
  .sliding-text p {
    font-family: bpg;
    font-size: 46px;
    color: #E7E8EA;
    cursor: pointer;
  }
  .sliding-text p .sliding-logos {
    width: 200px;
    -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
    filter: grayscale(100%);
  }

  .sliding-text p .sliding-logos:hover {
    -webkit-filter: none !important; /* Safari 6.0 - 9.0 */
    filter:none !important;
    transform: scale(1.2);
    transition: 1s, 1.5s;
  }
  .sliding-text p:hover {
    background-image: linear-gradient( to right, #ff3f95, #cc26d5, #7702ff);
    color: transparent;
    background-clip: text;
  }

  .sliding-text.paused {
    animation-play-state: paused;
  }
  
  @keyframes slideText {
    0% {
      transform: translateX(100%);
      
    }
    100% {
      transform: translateX(-100%);
    }

  }
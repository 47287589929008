#banner, h2 {
    margin:0;
}

#banner {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    margin-left:10rem ;
}
#banner h2 {
    font-family: LGV;
    font-size: 150px;
}

#p_Restaurant {
    font-size: 300px;
    position: relative;
    z-index: -1;
}
#p_Automatization {
    margin-left: 5rem;
}
#bannerImg {
    width: 900px;
    height: 778px;
    position: absolute;
    top: 6rem;
    right: 11rem;
    z-index: -1;
    
}

.bannerSVG {
    max-width: 800px;
}                  

.flexbox {
    align-items: flex-end;
    gap: 5rem;
    flex-wrap: nowrap;
}

#Miniheader {
    font-family: 'bpg';
    font-size: 46px;
    margin: 5rem auto;
}
#header {
    display: flex;
    align-items: center;
    justify-content: space-around;
}

#header img {
    width: 12rem;
}

#header p {
    font-family: bpg;
    cursor: pointer;
}

#header p:hover {
    opacity: 0.8;

}

#upRight {
    font-size: 30px;
}
#listing ul li {
    list-style-type: none;
    margin-bottom: 5px;

}
#listing ul li a {
    color: white;
    text-decoration: none;
}
#listing ul li a span {
    color: gray;
    margin-right: 1rem;

}

#listing ul li a:hover{
    opacity: 0.65;
    text-decoration: underline;
    text-underline-offset: 5px;
}
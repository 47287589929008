#ForGeorgiaSection {
    background-image: url('../../assets/img/slide background/slide\ background\ gradient\ with\ 292929.png');
    width: 100%;
}

#ForGeorgia {   
    width: 100%;
    height: 100vh;
    background-image: url('../../assets/img/slide background/geo\ ruqa\ gradient.svg');
    background-size: 80%;
    background-repeat: no-repeat;
    background-position: center center;
}

#contentHeader {
    display: block;
    width: 50%;
    margin:auto;
}
#ForGeorgiaContent h2 span{
    font-size:74px;
}
#ForGeorgiaContent h2 {
    font-family: 'LGV';
    font-size: 156px;
    /* display: flex
; */
    flex-direction: column;
    /* margin: auto; */
    /* width: 100%; */
}


#ForGeorgiaContent {
display: flex;
flex-direction: column;
align-items: flex-end;
}

#ForGeorgiaContent p {
    font-family:NotoSansGeorgian;
    font-size: 36px;
    max-width: 600px;
    color: rgb(39, 37, 37);
    position: relative;
    right: 4rem;
    margin: 0;
}
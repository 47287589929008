.pricing-section {
    color: white;
    max-width: 80%;
    margin: 5rem auto;
    font-family: 'NotoSansGeorgian';

  }
  
  .header {
    display: flex;
    justify-content: space-between;
    padding: 10px 20px;
    font-size: 16px;
  }
  
  .pricing-row {
    /* height: 5vh; */
    background: #292929;
    border: 2px solid transparent;
    border-radius: 20px;
    margin: 20px 0 34px 0;
    padding: 25px 20px;
    position: relative;
    cursor: pointer;
    transition: border 5s ease-in-out;
    box-shadow: 21px 21px 30px #212121, -21px -21px 30px #313131;
}
  
  /* .pricing-row:hover {
    border-image: linear-gradient(to right, #ff007f, #7f00ff) 1;
  } */

  .pricing-row:hover ::before {
        content: "";
        position: absolute;
        inset: 0;
        border-radius: 20px; 
        padding: 2px; 
        background:linear-gradient(to right, #ff007f, #7f00ff) ; 
        mask: 
          linear-gradient(#000 0 0) content-box, 
          linear-gradient(#000 0 0);
        mask-composite: exclude; 
        
  }
/*   
  .pricing-row.active {
    border-image: linear-gradient(to right, #ff007f, #7f00ff) 1;
  } */
  .pricing-row.active ::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 20px; 
    padding: 2px; 
    background:linear-gradient(to right, #ff007f, #7f00ff) ; 
    mask: 
      linear-gradient(#000 0 0) content-box, 
      linear-gradient(#000 0 0);
    mask-composite: exclude; 
}
  
  .pricing-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
  }
  
  .expandable-content {
    margin-top: 10px;
    font-size: 14px;
    color: #aaa;
  }
  

  .arrow {
    font-size: 18px;
    color: #7f00ff;
    transition: transform 0.3s ease;
  }
  
  .section-item.active .arrow {
    transform: rotate(180deg);
}


.option {
    max-width: 282px;
    margin: 0;
    flex: 1 1;
    font-weight: 200;
    font-variant: all-petite-caps;
    font-size: 24px;
}

#firstSpan {
    max-width: 282px;
    margin: 0;
    flex: 1;
}

.pricing-list-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px 0;
  }
  
  .list-title {
    flex: 2 1;
    font-size: 14px;
    max-width: 230px;
}
  
  .plan-icons {
    display: flex;
    gap: 15px;
    flex: 1;
    justify-content: space-evenly;
  }
  
  .plan-icon .icon {
    font-size: 36px;
    color: gray;
  }
/*   
  .plan-icon .icon.basic {
    color: #ff007f; 
  }
  
  .plan-icon .icon.pro {
    color: #7f00ff; 
  }
  
  .plan-icon .icon.enterprise {
    color: #00ff7f; 
  } */
  